import React from "react"
import Layout from "../components/layout"

const AboutPPT = () => {
  return (
    <Layout>
      <div style={{ paddingTop: "200px", paddingBottom: "100px" }}>
        <div className="container">
          <h1 className="text-blue text-center mb-5">About PeerSource</h1>
          <iframe
            src="https://view.officeapps.live.com/op/embed.aspx?src=https://res.cloudinary.com/dwzjvi83z/raw/upload/v1611574949/PeerSource_Company_Presentation_JM_rny6zv.pptx"
            height="600px"
            frameborder="0"
            style={{ width: "100%" }}
            title="About PeerSource"
          >
            This is an embedded{" "}
            <a target="_blank" rel="noreferrer" href="https://office.com">
              Microsoft Office
            </a>{" "}
            presentation, powered by{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://office.com/webapps"
            >
              Office
            </a>
            .
          </iframe>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPPT
